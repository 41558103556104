import * as React from "react"
import Layout from "../components/layout2023"
import SeoNoIndex from "../components/seo-no-index-ablyft"
import HowItWorksComponent from "../components/how-it-works-new/how-it-works"

const HowItWorks = () => (
  <Layout nav={"hidden"}>
    <SeoNoIndex title="How it works" index={false} follow={false} />
    <HowItWorksComponent wrapperClass={"prediction-engine how-it-works"} />
  </Layout>
)

export default HowItWorks
