import React, { lazy, Suspense, useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import CountUp from 'react-countup'
import { useInView } from "react-intersection-observer"
import SiteNavigation from "../nav-new/nav"
import Image from "../image-component/image-2023"
import FormSection from "./how-it-works-form"
import FallbackLoading from "../loader/loader";
import ReactCompareImage from 'react-compare-image';
import Slider from "react-slick"
import "./how-it-works.scss"
const SliderSection = lazy(() => import('./slider'))

const leftSliderData = [
  {
    controlImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/patriot-control.webp',
    variantImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/patriot-variant.webp',
    comparisonCaption: 'Patriot tested this Bulletized Hero layout on their homepage. Our scraper detected it running from 06.19.21 to 10.21.21. The variant won. The scraper captures 12,000 tests like this every month, so you benefit from the global wisdom of all A/B tests.'
  },
  {
    controlImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/signeasy-control.webp',
    variantImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/signeasy-variant.webp',
    comparisonCaption: 'SignEasy tested this Personalized Hero and Interface layout on their homepage. Our scraper detected it running from 05/20/22 to 06/04/22. The variant won. The scraper captures 12,000 tests like this every month, so you benefit from the global wisdom of all A/B tests.'
  },
  {
    controlImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/servicetitan-control.webp',
    variantImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/servicetitan-variant.webp',
    comparisonCaption: 'Service Titan tested this Personalized Hero Copy layout on their homepage. Our scraper detected it running from 07/05/22 to 11/07/22. The variant won. The scraper captures 12,000 tests like this every month, so you benefit from the global wisdom of all A/B tests.'
  },
  {
    controlImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/shootproof-control.webp',
    variantImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/shoot-proof-variant.webp',
    comparisonCaption: 'ShootProof tested this lifestyle image and 3-bullets layout on their homepage. Our scraper detected it running from 10/05/21 to 11/29/21. The variant won. The scraper captures 12,000 tests like this every month, so you benefit from the global wisdom of all A/B tests.'
  }
]
export default function HowItWorks(props) {
  const { ref, inView } = useInView({
    threshold: 0
  });
  const [currentSlide, setCurrentSlide] = useState(1)
  const customSlider = useRef();
  const isSSR = typeof window === "undefined"
  const setTopValue = () => {
    if (window.innerWidth < 768) {
      document.querySelector('.slider-controls').style.top = document.querySelector('.slide-item div[data-testid="container"]').offsetHeight + 38 + 'px'
    } else {
      document.querySelector('.slider-controls').style.top = ''
    }
  }
  useEffect(() => {
    window.addEventListener('load', setTopValue, false)
    window.addEventListener('resize', setTopValue, false)
    window.addEventListener('scroll', setTopValue, false)
    return () => {
      window.removeEventListener('load', setTopValue, false)
      window.removeEventListener('resize', setTopValue, false)
      window.removeEventListener('scroll', setTopValue, false)
    }
  }, [])

  return (
    <span className={props.wrapperClass}>
      <section className={"hero-section"}>
        <SiteNavigation />
        <div className="hero-content-wrap">
          <div className="hero-left">
            <div className="hero-superheading">How it Works</div>
            <div className="hero-heading">Get 3X wins by using proven winners. Everything done for you. Fast!</div>
            <div className="hero-subheading">
              <p>The Spiralyze prediction engine captures winning A/B tests from the 34,000 sites that actively test to predict what will win for your site. Our full service team handles all the design and dev to run the tests on your site</p>
              <p>Clients average 38% more quality conversions <br />in the first 90 days.</p>
            </div>
            <div className="page-anchors"><Link to="/how-it-works#prediction-engine">Prediction Engine</Link> · <Link to="/how-it-works#full-service-team">Full Service Team</Link> · <Link to="/how-it-works#process">Process</Link> · <Link to="/how-it-works#results">Results</Link></div>
            <Link className="arrow-button" to="/get-demo/">Get a Demo --></Link>
          </div>
          <div className="hero-right">
            <Image
              sourceFolder={'how_it_works'}
              lazyLoad={"false"}
              alt={"hero image"}
              fallBackImage={'how-it-works-hero.webp'}
              imgName={[
                {
                  imageName: "how-it-works-hero.webp",
                  minBreakpoint: "992px",
                },
                {
                  imageName: "how-it-works-hero.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "how-it-works-hero-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
        </div>
      </section>
      <section className="social-proof-section">
        <Image
          sourceFolder={'homepage'}
          lazyLoad={"true"}
          alt={"Social proof"}
          fallBackImage={'2023-social-logos-mobile.webp'}
          imgName={[
            {
              imageName: "2023-social-logos-desktop.webp",
              minBreakpoint: "992px",
            },
            {
              imageName: "2023-social-logos-tablet.webp",
              minBreakpoint: "768px",
            },
            {
              imageName: "2023-social-logos-mobile.webp",
              minBreakpoint: "320px",
            },
          ]}
        />
      </section>
      <section className="scraper-section" id="prediction-engine">
        <div className="scraper-items-wrap">
          <div className="scraper-left">
            <div className="slider-controls">
              <img src="https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/slide-left.svg" className="slide-prev" onClick={() => customSlider.current.slickPrev()} />
              <span className="slides-num"><span>{currentSlide} /</span> 4</span>
              <img src="https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/slider-right.svg" className="slide-next" onClick={() => customSlider.current.slickNext()} />
            </div>
            <div className="slider-left">
              <Slider
                draggable={false}
                swipeToSlide={false}
                touchMove={false}
                slidesToShow={1}
                arrows={true}
                beforeChange={(currentSlide, nextSlide) => setCurrentSlide(nextSlide + 1)}
                ref={(slider1) => {
                  customSlider.current = slider1
                }
                }
              >
                {leftSliderData.map((item, index) => {
                  return (
                    <div className="slide-item" key={index}>
                      <ReactCompareImage
                        handle={<img src="https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/drag.svg" />}
                        handleSize={56}
                        sliderLineColor={'#2BA3F7'}
                        leftImage={item.controlImage}
                        rightImage={item.variantImage} />
                      <div className="comparison-caption">{item.comparisonCaption}</div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
          <div className="scraper-right">
            <div className="slider-right">
              <div className="scraper-super-heading">Prediction Engine</div>
              <div className="scraper-heading" ><span>Know what wins.</span> Based on data from 130,000+ A/B tests.</div>
              <div className="scraper-subheading">The prediction engine <Link to="/scraper/">scrapes</Link> the internet, finding A/B tests and tracking the outcomes. It captures 12,000 new tests every month. So you harness the global wisdom of all A/B tests.</div>
              <Link className="arrow-button-outline" to="/prediction-engine/">Prediction Engine  --></Link>
            </div>
          </div>
        </div>
      </section>
      <section className="how-it-works">
        <div className="row-wrap reverse" id="full-service-team">
          <div className="row-left">
            <div className="row-super-heading">Full Service Team</div>
            <div className="row-heading"><span>Everything done for you.</span> Research, design, copy, & dev.</div>
            <div className="row-text">
              <p>Our agency-style delivery model handles all the details for you.  Get a dedicated team including research, copywriting, design, development, QA, analytics, and project management.</p>
              <p>Get your first test live two weeks after kickoff. A <span >full pipeline</span> of tests in two months.</p>
            </div>
            <Link className="arrow-button-outline" to="/full-service-team">Full Service Team  --></Link>
          </div>
          <div className="row-right">
            <Image
              sourceFolder={'homepage'}
              lazyLoad={"true"}
              alt={"Full service team"}
              fallBackImage={'2023-full-service-desktop.webp'}
              imgName={[
                {
                  imageName: "2023-full-service-desktop.webp",
                  minBreakpoint: "992px",
                },
                {
                  imageName: "2023-full-service-tablet.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "2023-full-service-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
        </div>
        <div className="row-wrap" id="process">
          <div className="row-left">
            <Image
              sourceFolder={'how_it_works'}
              lazyLoad={"true"}
              alt={"Clear testing roadmap"}
              fallBackImage={'testing-roadmap-desktop.webp'}
              imgName={[
                {
                  imageName: "testing-roadmap-desktop.webp",
                  minBreakpoint: "992px",
                },
                {
                  imageName: "testing-roadmap-desktop.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "testing-roadmap-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
          <div className="row-right">
            <div className="row-super-heading">Process</div>
            <div className="row-heading"><span>Clear testing roadmap.</span> An evidence based iterative process.</div>
            <div className="row-text">
              <p>Plan a testing roadmap with your next 3 months of tests - based on evidence from the prediction engine. Continually optimized as new test data comes in.</p>
              <p>Maximize the testing potential from your traffic. Minimize downtime between tests.</p>
            </div>
            <Link to="/process/" className="arrow-button-outline">Process  --></Link>

          </div>
        </div>
        <div className="row-wrap" id="results">
          <div className="row-right">
            <div className="row-super-heading">Fast Results</div>
            <div className="row-heading"><span>Your first win in 28 days.</span> 30% lift in 3 months. Guaranteed.</div>
            <div className="row-text">
              <p>Combining proven winners from the prediction engine with the fast execution of the full-service team gets you fast results.</p>
              <p>We can even guarantee results with<Link to="/pricing/"> 100% performance based fees.</Link></p>
            </div>
            <Link className="arrow-button-outline">Results  --></Link>
          </div>
          <div className="row-left stats">
            <Image
              sourceFolder={'how_it_works'}
              lazyLoad={"true"}
              alt={"results"}
              fallBackImage={'how-it-works-results.webp'}
              imgName={[
                {
                  imageName: "how-it-works-results.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "how-it-works-results-mobile.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
            <div className="animated-stats" ref={ref}>
              <div className="stat-item">
                <div className="num-val"><CountUp end={10} enableScrollSpy={true} /></div>
                <div className="gradient-bar-wrap">
                  <div className="bar-desc">Tests Launched (in first 90 days)</div>
                  <div className={inView ? "gradient-bar animated" : "gradient-bar"}></div>
                </div>
              </div>
              <div className="stat-item">
                <div className="num-val"><CountUp end={34} enableScrollSpy={true} />%</div>
                <div className="gradient-bar-wrap">
                  <div className="bar-desc">Test Win Rate</div>
                  <div className={inView ? "gradient-bar animated" : "gradient-bar"}></div>
                </div>
              </div>
              <div className="stat-item">
                <div className="num-val"><CountUp end={38} enableScrollSpy={true} />%</div>
                <div className="gradient-bar-wrap">
                  <div className="bar-desc">Average Lift</div>
                  <div className={inView ? "gradient-bar animated" : "gradient-bar"}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FormSection />
      {
        !isSSR && (
          <Suspense fallback={<FallbackLoading />}>
            <SliderSection />
          </Suspense>
        )
      }
    </span >
  )
}
